@import url("https://fonts.googleapis.com/css2?family=Padauk:wght@400;700&display=swap");
@import "~bootstrap/dist/css/bootstrap.css";
// Font Sizes
$font-small: 0.8rem;
$font-normal: 1rem;
$font-medium: 1.1rem;
$font-big: 1.2rem;
$font-XXL: 1.5rem;

@import "../../../../node_modules/@angular/material/theming";
@include mat-core();

//Material app colors
$my-primary: mat-palette($mat-gray, 800);
$my-accent: mat-palette($mat-gray, 400);
$my-theme: mat-light-theme($my-primary, $my-accent);
@include angular-material-theme($my-theme);

//Font Family
$font-fam: "Padauk", sans-serif;

//App colors
$color-red: #535355;
$color-gray: #afafaf;
$color-black: #000000;
$color-dark-gray: #111111;

.grid-item-container {
  display: grid;
  grid-template-columns: 1fr;
}

.form-edit-item {
  display: grid;
  grid-column: 1fr;
}

.router-container {
  margin-top: 3rem;
}

.mat-card > :first-child,
.mat-card-content > :first-child {
  width: 100%;
}

.title-list {
  padding: 10px;
}

.subtitle-list {
  padding: 5px;
}

.mat-column-Edit {
  width: 32px;
  padding-right: 24px;
  text-align: center;

  // .mat-button{

  // }
}

.icon-card {
  padding-right: 12rem;
}

.padding {
  padding: 30px;
}

.green-dot {
  width: 15px;
  height: 15px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
}

.serial-data-display {
  display: grid;

  margin-left: 70%;
  padding-left: 30%;
  .item-active {
    font-size: 80px;
    padding-bottom: 4vh;
    padding-right: 4rem;
    margin-top: -4vh;
  }
  hr {
    color: rgb(226, 226, 226);
  }
}

.temp {
  color: black;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.big-text {
  font-family: $font-fam;
  font-size: $font-XXL;
  font-weight: bold;
  padding: 30px;
}

.medium-text {
  font-size: $font-big;
  font-weight: bold;
  padding: 30px;
}

.image {
  height: 80px;
}

// .tag-view{
//   width: 30%;
// }

.card-edit {
  width: 50%;
  margin-left: 25%;
  align-content: center;
  .form-edit-item {
    width: 70%;
    margin-left: 15%;
  }
}

.item-title {
  display: grid;
  grid-template-columns: 2fr 6fr 5fr 1fr 4.8fr;

  .add-button {
    align-self: center;
    width: 70px;
  }
  .add-right {
    align-self: right;
    width: 200px;
    justify-self: flex-end;
  }
}

.serial-data-display {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-left: 15%;
  margin-right: 15%;
}

.wrapper-item-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 0.8fr;
  font-family: $font-fam;
  font-size: $font-small;
  line-height: 1.5rem;
  text-align: center;
  height: 1.8rem;
  margin-bottom: -1rem;
  background-color: #f5f5f5;

  button {
    margin-bottom: 0.5rem;
    margin-top: -0.3rem;
  }
  .serial-number {
    background-color: $color-gray;
    // border-radius: 20px;
    font-size: $font-normal;
    margin-bottom: 1rem;
    margin-top: 0.3rem;
    padding-left: 2%;
    padding-right: 2%;
    color: $color-dark-gray;
    // min-width: 5rem;
    font-weight: bold;
  }

  .green-dot {
    width: 1rem;
    height: 1rem;
    background-color: green;
    border-radius: 100%;
    margin-top: 0.5rem;
  }

  .red-dot {
    width: 1rem;
    height: 1rem;
    background-color: $color-red;
    border-radius: 100%;
    margin-top: 0.5rem;
  }
}

.search-bar {
  height: 40px;
  padding-left: 10px;
}

mat-card-header {
  margin-bottom: 10px;
}

// table {
//   width: 100%;
// }
.component-list {
  padding: 2rem 2rem;
  height: 770px;
}
//MOVILE RESPONSIVENESS

@media (max-width: 750px) {
  /* @keyframes for the gradient animation */
  @keyframes bg-hue {
    100% {
      filter: hue-rotate(360deg);
    }
  }

  .card-edit {
    align-content: center;
  }

  .search-bar {
    height: 40px;
  }

  // button{
  //   max-width: 250px;
  // }

  .container-center {
    text-align: center;
    align-content: center;
  }

  .container-input {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
  }
  .wrapper-item-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 0.8fr;
    font-family: $font-fam;
    font-size: $font-small;
    line-height: 1.5rem;
    text-align: center;

    .serial-number {
      background-color: $color-gray;
      border-radius: 20px;
      font-size: $font-small;
      margin-bottom: 1rem;
      color: $color-dark-gray;
      // min-width: 5rem;
    }

    .green-dot {
      width: 1rem;
      height: 1rem;
      background-color: green;
      border-radius: 100%;
      margin-top: 0.5rem;
    }

    .red-dot {
      width: 1rem;
      height: 1rem;
      background-color: $color-red;
      border-radius: 100%;
      margin-top: 0.5rem;
    }
  }
}

body {
  min-height: 100%;
}

.object-table {
  width: 100%;
  align-items: center;
}

// .mat-column-Active {
//   width: 32px;
//   padding-right: auto;
//   padding-left: auto;
//   text-align: center;
// }

.mat-column-SerialNumber {
  width: 32px;
  padding-right: auto;
  padding-left: auto;
  text-align: center;
}

// .mat-column-Action {
//   width: 32px;
//   padding-right: auto;
//   padding-left: auto;
//   text-align: center;
// }

.mat-column-ObjectSerialNumber {
  width: 32px;
  padding-right: auto;
  padding-left: auto;
  text-align: center;
}

.mat-column-TagSerialNumber {
  width: 32px;
  padding-right: auto;
  padding-left: auto;
  text-align: center;
}

.mat-column-Fecha {
  width: 32px;
  padding-right: auto;
  padding-left: auto;
  text-align: center;
}

.mat-column-StartDate {
  width: 32px;
  padding-right: auto;
  padding-left: auto;
  text-align: center;
}

.mat-column-EndDate {
  width: 32px;
  padding-right: auto;
  padding-left: auto;
  text-align: center;
}

.mat-column-CarSerial {
  width: 32px;
  padding-right: auto;
  padding-left: auto;
  text-align: center;
}

.mat-column-UserId {
  width: 32px;
  padding-right: auto;
  padding-left: auto;
  text-align: center;
}

// .mat-column-Name {
//   width: 32px;
//   padding-right: auto;
//   padding-left: auto;
//   text-align: center;
// }

.mat-column-RoleId {
  width: 32px;
  padding-right: auto;
  padding-left: auto;
  text-align: center;
}

.mat-column-Email {
  width: 32px;
  padding-right: auto;
  padding-left: auto;
  text-align: center;
}

.title {
  font-weight: bold;
}

.mat-form-Option {
  width: 200px;
  padding-right: 10px;
}


.mat-column-UserComponentId {
  width: 19%;
  padding-right: auto;
  padding-left: auto;
  text-align: center;
}

.mat-column-UserComponentName {
  width: 19%;
  padding-right: auto;
  padding-left: auto;
  text-align: center;
}

.mat-column-UserComponentRole {
  width: 19%;
  padding-right: auto;
  padding-left: auto;
  text-align: center;
}

.mat-column-UserComponentEmail {
  width: 19%;
  padding-right: auto;
  padding-left: auto;
  text-align: center;
}

.mat-column-UserComponentAction {
  width: 19%;
  padding-right: auto;
  padding-left: auto;
  text-align: center;
}

#loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 120px;
  height: 120px;
  margin: -76px 0 0 -76px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #555;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.ListHide {
  display: none !important;
}

.overlay {/* Full height (cover the whole page) */
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
}

.custom-modalbox {
  mat-dialog-container {
      height: unset;
  }
}


.mat-column-Name {
  width: 10%;
  padding-right: auto;
  padding-left: auto;
  text-align: center;
}

.mat-column-Description {
  width: 10%;
  padding-right: auto;
  padding-left: auto;
  text-align: center;
}

.mat-column-LoadStatus {
  width: 5%;
  padding-right: auto;
  padding-left: auto;
  text-align: center;
}

.mat-column-FileName {
  width: 15%;
  padding-right: auto;
  padding-left: auto;
  text-align: center;
}

.mat-column-YearToProcess {
  width: 5%;
  padding-right: auto;
  padding-left: auto;
  text-align: center;
}

.mat-column-MonthToProcess {
  width: 10%;
  padding-right: auto;
  padding-left: auto;
  text-align: center;
}

.mat-column-UserCreation {
  width: 10%;
  padding-right: auto;
  padding-left: auto;
  text-align: center;
}

.mat-column-CreatedAt {
  width: 10%;
  padding-right: auto;
  padding-left: auto;
  text-align: center;
}

.mat-column-UpdatedAt {
  width: 10%;
  padding-right: auto;
  padding-left: auto;
  text-align: center;
}

.mat-column-Action {
  width: 15%;
  padding-right: auto;
  padding-left: auto;
  text-align: center;
}
